<template>
  <section>
    <v-row class="pa-2" justify="center" style="margin-top: -150px">
      <v-col cols="12" xs="12" sm="8" style="max-width: 800px">
        <v-card flat tile class="pa-5 text-center white mainBlack--text">
          <div class="pa-5 test" style="border: 2px solid black; ">
            <div class="mb-10 headline">{{ callout[0] }}</div>
            <div class="headline">{{ callout[1] }}</div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: 'Callout',
  data: () => ({
    callout: ['The Title Girl is ready to assist you in every process related to your car registration', 'Let us work for you, reach us now!']
  })
};
</script>
